.chat-container {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    z-index: 1;
    margin-bottom: 10px;
}

.loader {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.loader span {
    width: 10px;
    height: 10px;
    background-color: #3498db;
    border-radius: 50%;
    animation: dot-animation 1.5s infinite ease-in-out;
    margin: 0 4px;
}

@keyframes dot-animation {

    0%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(15px);
    }

    50% {
        transform: translateX(30px);
    }

    75% {
        transform: translateX(15px);
    }
}

.loader::after {
    content: "\2026";
    color: #3498db;
    font-size: 32px;
    animation: dot-animation 1.5s infinite;
}

.dropdown-container {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
}

.no-messages .dropdown-container {
    margin-top: 10px;
}
.chat-container {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
}

.chat-messages {
    margin-bottom: 10px;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
}

.user {
    background-color: #3498db;
    color: #fff;
    text-align: right;
}

.ai {
    background-color: #f1f1f1;
    color: #333;
    text-align: left;
}

.user::before,
.ai::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 5px;
}

.user::before {
    background-color: #fff;
}

.ai::before {
    background-color: #333;
}

.no-messages {
    text-align: center;
    color: #999;
    font-style: italic;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(1px); /* Adjust the blur value as needed */
}
