
.pcoded-main-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .header h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .bg-gray-100 {
    background-color: #f7fafc;
    text-align: justify;
  }
  
  .input-view {
    display: inline-block;
    padding: 10px;
    border-radius: 4px;
    background-color: #f7fafc;
    color: #333;
  }
  
  input[type="text"],
  textarea,
  select {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    background-color: white;
  }
  
  input[type="checkbox"] {
    transform: scale(1.2);
  }
  
  .btn-edit,
  .btn-save,
  .btn-cancel {
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }  
  
  .btn-save {
    background-color: #fb5f65;
    color: white;
  }
  
  .btn-cancel {
    background-color: #6c757d;
    color: white;
  }
  
  
  .btn-save:hover {
    background-color: #0056b3;
  }
  
  .btn-cancel:hover {
    background-color: #5a6268;
  }
  
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .preloader img {
    width: 100px;
  }
  

  textarea {
    resize: vertical;
  }
  
  textarea,
  input[type="text"] {
    border: 1px solid #d1d5db;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.5;
  }
  
  textarea {
    min-height: 100px;
    max-height: 400px;
  }
  

  .bg-gray-100 p {
    font-size: 14px;
    color: #666;
  }
  
  input[type="checkbox"]:disabled {
    background-color: #e4e4e4;
  }
  

  .deviate-item,
  .role-item {
    margin-bottom: 15px;
  }
  
  .deviate-item input,
  .role-item input {
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .deviate-item textarea,
  .role-item textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .system-role-list,
  .deviate-prompt {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .deviate-item,
  .role-item {
    margin-bottom: 15px;
  }
  
  .bg-gray-100 p {
    background-color: #f3f4f6;
    padding: 8px;
    border-radius: 5px;
    color: #333;
  }
  
  .field {
    margin-bottom: 20px;
  }
  
  .field label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
  }
  
  .edit-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    gap:10px;
  }
  
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .input-view,
  .bg-gray-100 {
    background-color: rgba(249, 245, 242, 0.5);
    padding: 10px;
    border-radius: 4px;
  }
  
  textarea {
    background-color: rgba(249, 245, 242, 0.5);
    border: 1px solid #ddd;
    height: auto !important;
    pointer-events: all !important;
  }
  
  select {
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  .input-view {
    font-size: 16px;
    color: #333;
  }
  
  .input-view {
    display: block;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    background-color: #f7fafc;
    color: #333;
  }
  
  input[type="checkbox"]:checked {
    background-color: #007bff;
  }
  
  .field {
    margin-bottom: 20px;
  }
  

  .dropdown {
    width:10%;
  }

  .dropdown-label{
    font-size: 16px;
  }

  .topicHeading{
    font-size: 16px;
  }

  .topicSubHeading{
    font-size: 16px;
  }