@import"https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800&display=swap');


body{ font-family:  "Lexend", sans-serif;}  
.btn{padding: 12px 22px; cursor: pointer; position: relative; box-shadow: none!important; color: #fff; 
    background: var(--themebg); letter-spacing: 0; font-size: 16px; line-height: 1.25; font-weight: 500; text-align: center; 
    border-radius: 8px; border: 1px solid var(--themebg);; text-decoration: none;}
.btn:hover,
.btn:focus{  background-color: var(--btn-hover);  color: #fff; border-color: var(--btn-hover);}
.btn:active{  background-color: var(--btn-hover) !important;  color: #fff; border-color: var(--btn-hover) !important;}
.form-control {outline: none!important; padding: 12px 20px; background: #fff !important; box-shadow: none!important; 
    font-size: 16px; font-weight: 400; font-family:  "Lexend", sans-serif; color: #1e1e1e !important; line-height: 16px; border: 1px solid rgba(34,34,34,.3); border-radius: 8px; 
    width: 100%; }
.form-control:focus {border-color: var(--themebg);;}
.login-section { padding: 50px 0; min-height: 100vh; display: flex; align-items: center; } 
.loginForm_component__main { position: relative;}
img.lf__img{ position: absolute;  bottom: 20px;  left: 0;}
.form__heading { font-weight: 500; font-size: 24px; margin-bottom: 15px; } 
.lfc__form  { max-width: 459px; margin: auto; padding: 50px; text-align: center; background: #fff; position: relative; z-index: 1; } 
.box-shadow { box-shadow: 2px 4px 25px #fb5f651a; } 
.br-20 { border-radius: 20px; }
.container { max-width: 1036px;   margin: auto;}

.nav-tabs .nav-item .nav-link {  border: 1px solid var(--themebg);   border-radius: 5px;    color: var(--link-clr);}
.nav-tabs .nav-item .nav-link:hover{  background: var(--themebg); color: #fff;  }
.accordion-button:not(.collapsed) {color: #fff;background-color: var(--themebg);box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);}
.accordion-button:not(.collapsed)::after {
    background-image: url(../assets/images/ard-arrow.svg);}